import { ApiService, ConfigApi, SettingsService, StorageService } from 'services';
import {
    IConfigApi,
    IApiService,
    ISettingsService,
    IStorageService,
    INotificationOrderService,
    IActionApi,
    ICommitteeApi,
} from 'types';
import { NotificationOrderService } from './notification-order.service';
import { ActionsApi } from 'services/api/action.api';
import { CommitteeApi } from './api/committee.api';

export const getApiService = (tokenStorageService: IStorageService): IApiService => new ApiService(tokenStorageService);
export const getConfigApiService = (apiService: IApiService): IConfigApi => new ConfigApi(apiService);
export const getActionsApiService = (apiService: IApiService): IActionApi => new ActionsApi(apiService);
export const getCommitteeApiService = (apiService: IApiService): ICommitteeApi => new CommitteeApi(apiService);
export const getSettingsService = (): ISettingsService => new SettingsService();
export const getStorageService = (): IStorageService => new StorageService();
export const getNotificationOrderService = (): INotificationOrderService =>
    new NotificationOrderService(getApiService(getStorageService()));
