import React, { FC, ReactElement } from 'react';
import { Card, Inner, Front, Back } from './styled';

interface FlipCardProps {
    front: ReactElement;
    back: ReactElement;
    isActive: boolean;
    onClick: () => void;
    preventFlip?: boolean;
}

const FlipCard: FC<FlipCardProps> = ({ front, back, onClick, isActive, preventFlip }) => (
    <Card onClick={onClick} preventFlip={preventFlip}>
        <Inner isActive={isActive}>
            <Front>{front}</Front>
            <Back>{back}</Back>
        </Inner>
    </Card>
);

export default FlipCard;
