import React, { FC } from 'react';
import { ImageContainer, Name, Text, Position, Flip, ContentCard } from './styled';
import { UserBio } from 'types';
import { useTranslation } from 'react-i18next';
import { Icon } from 'kl-b2c-ui-kit';

export const UserBioFront: FC<UserBio> = ({ name, position, twitter, image, description }) => {
    const { t } = useTranslation('pages/home');

    return (
        <ContentCard>
            <ImageContainer background={image} />
            <Text>
                <Name>{name}</Name>
                <Position>{position}</Position>
                {(twitter || description) && (
                    <Flip>
                        <span>{t('flip')}</span>
                        <Icon name={'Refresh'} size={18} color="teal" />
                    </Flip>
                )}
            </Text>
        </ContentCard>
    );
};
