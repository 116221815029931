import React, { FC, useEffect, useState } from 'react';
import { TagsContainer, Tag } from './styled';

type TagsProps = {
    tags: { key: string; label: string }[];
    onChange: (chosen: string[]) => void;
};

const Tags: FC<TagsProps> = ({ tags, onChange }) => {
    const [activeKeys, setActiveKeys] = useState<string[]>([]);

    useEffect(() => {
        onChange(activeKeys);
    }, [activeKeys]);

    return (
        <TagsContainer>
            {tags.map((t) => (
                <Tag
                    active={activeKeys.some((key) => key === t.key)}
                    key={t.key}
                    onClick={() => {
                        if (activeKeys.some((key) => key === t.key)) {
                            setActiveKeys(activeKeys.filter((key) => key !== t.key));
                        } else {
                            setActiveKeys((prevState) => [...prevState, t.key]);
                        }
                    }}
                >
                    {t.label}
                </Tag>
            ))}
        </TagsContainer>
    );
};

export default Tags;
