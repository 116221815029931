import styled from 'styled-components';
import { mediaLg, mediaMd, mediaSm } from '../../../../../../theme/theme-helper-selectors';

export const ImageContainer = styled.div`
    height: 820px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-image: url('/assets/images/sas.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 0 205px 0;
    position: relative;
    border-radius: 32px;

    @media (${mediaMd}) {
        margin: 0 0 169px 0;
    }

    @media (${mediaSm}) {
        margin: 0 0 80px 0;
        height: 574px;
    }
`;

export const Title = styled.h1`
    font-size: 281px;
    opacity: 0.8;
    margin: 0 0 -20px 0;

    @media (${mediaMd}) {
        font-size: 208px;
    }

    @media (${mediaSm}) {
        font-size: 114px;
    }
`;

export const Year = styled.span`
    color: ${({ theme }) => theme.palette.precisionBlue};
`;

export const MarqueeContainer = styled.div`
    height: 92px;
    width: calc(100% + 128px);
    position: absolute;
    transform: rotate(-4deg);
    left: -64px;
    bottom: -75px;
    display: flex;

    @media (${mediaLg}) {
        left: -48px;
        width: calc(100% + 106px);
        bottom: -37px;
    }

    @media (${mediaSm}) {
        left: -16px;
        width: calc(100% + 42px);
        height: 59px;
    }

    svg {
        width: 54px;
        height: 54px;

        @media (${mediaSm}) {
            height: 34px;
            width: 34px;
        }
    }
`;

export const MarqueeItem = styled.span`
    color: ${({ theme }) => theme.palette.text.primary};
    opacity: 0.8;
    margin: 0 16px;
    font-size: 42px;
    line-height: 38px;
    display: flex;
    justify-content: center;
    gap: 5px;
    font-weight: 400;
    min-width: 225px;

    @media (${mediaSm}) {
        font-size: 27px;
        line-height: 24px;
        min-width: 145px;
    }
`;

export const MarqueeDayContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const MarqueeDayLabel = styled.div`
    font-size: 12px;
    line-height: 13px;

    @media (${mediaSm}) {
        font-size: 8px;
        line-height: 9px;
    }
`;

export const MarqueeBg = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(21px);
    background-color: rgba(178, 178, 178, 0.14);
`;
