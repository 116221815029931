import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultTheme, withTheme } from 'styled-components';
import {
    BlockTitleContainer,
    OpportunitiesItemsContainer,
    CardImage,
    CardTitle,
    CardDescription,
    CardButton,
    BlockCard,
    OpportunitiesContainer,
} from './styled';
import RegistrationPricing from './RegistrationPricing';
import GradientTitle from 'components/gradient-title/GradientTitle';
import DOMPurify from 'dompurify';

const Opportunities: FC<{ theme: DefaultTheme }> = ({ theme }) => {
    const { t } = useTranslation('pages/home');

    return (
        <OpportunitiesContainer>
            <BlockTitleContainer>
                <GradientTitle
                    variant="h1"
                    label={t('opportunities-title')}
                    startColor="#29C2CC"
                    finishColor="#4AFFE9"
                    startLabelColor={theme.palette.black}
                    finishLabelColor={theme.palette.white}
                />
            </BlockTitleContainer>
            <OpportunitiesItemsContainer>
                <BlockCard>
                    <CardImage src="/assets/images/cocktail.png" alt="Cocktail image" />
                    <CardTitle>{t('opp-attendee')}</CardTitle>
                    <RegistrationPricing />
                    <CardButton to={'/registration'}>{t('opp-attendee-btn')}</CardButton>
                </BlockCard>
                <BlockCard>
                    <CardImage src="/assets/images/bird.png" alt="Bird image" />
                    <CardTitle>{t('opp-speaker')}</CardTitle>
                    <CardDescription>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(t('opp-speaker-description')),
                            }}
                        />
                    </CardDescription>
                    <CardButton to={'/papers'}>{t('opp-speaker-btn')}</CardButton>
                </BlockCard>
                <BlockCard>
                    <CardImage src="/assets/images/dog.png" alt="Dog image" />
                    <CardTitle>{t('opp-sponsor')}</CardTitle>
                    <CardDescription>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(t('opp-sponsor-description')),
                            }}
                        />
                    </CardDescription>
                    <CardButton to={'/sponsors'}>{t('opp-sponsor-btn')}</CardButton>
                </BlockCard>
            </OpportunitiesItemsContainer>
        </OpportunitiesContainer>
    );
};

export default withTheme(Opportunities);
